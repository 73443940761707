import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  " - 내년 상반기 중 각 가정에 ‘반려로봇’ 들일 수 있을 것으로 전망",
  " - 뉴노멀 시대에 맞춰 AI 로봇 기반 키즈 & 시니어 대상 케어 서비스 구축",
]

const content = () => (
  <div>
    <style>
      {
        "\
        .pressDetailPage .pressDetailBody .contentArea .descImage {\
          display: table;\
          width: 100%;\
        }\
        .pressDetailPage .pressDetailBody .contentArea .descImage div {\
          display: table-cell;\
          vertical-align: middle;\
        }\
        "
      }
    </style>
    <div className="descImage">
      <div>
        <img src="/images/press/4/oFHGpgpn.jpg" alt="AI 반려로봇 공동 개발 사업 협약 (MOU)" />
      </div>
      <div>
        <img src="/images/press/4/QVAmYQCn.jpg" alt="반려로봇" />
      </div>
    </div>
    <br />
    <p>
      <br />
      ㅣ 스테이지파이브(공동대표 서상원, 이제욱)은 17일 오전 스테이지파이브 본사에서 KT, NUWA ROBOTICS, 아쇼카 한국 4사간 ‘AI
      반려로봇 공동 개발을 위한 업무협약’을 체결한다고 밝혔다. 각 사들은 통신 및 AI, 로봇, 콘텐츠와 관련하여 보유한 역량과 기술을
      집결, 키즈와 시니어를 대상으로 한 차세대 반려로봇 개발을 위해 힘을 모을 예정이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 각 사는 로봇 현지화 및 콘텐츠 제휴(스테이지파이브), 로봇 내 AI 탑재 및 시장발굴(KT), 로봇 디바이스 고도화(NUWA),
      소셜임팩트 중심의 콘텐츠 설계(아쇼카) 등을 위해 광범위하게 협력하기로 합의하였다.
      <br />
    </p>
    <br />
    <p>
      ㅣ KT와 스테이지파이브는 작년 2019년 8월 MOU 맺으며 미래사업 발굴을 공동 추진하기로 한 바 있다. 이번 협약은 양 사간의
      협력에서 나아가 생활밀착형 IOT 개발사업을 위한 생태계를 조성하는데 일조하면서, 기존으로부터 진일보 했다는 평가를 받는다.
      <br />
    </p>
    <br />
    <p>
      ㅣ NUWA ROBOTICS는 로봇 관절·플랫폼 등 하드웨어와 소프트웨어 자체 기술을 보유한 로봇 전문 기업으로, 지난 4월
      스테이지파이브와 전략제휴 맺은 바 있다. 앞으로도 해당 기업은 로봇 디바이스 고도화에 그 몫을 다할 예정이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 아쇼카 한국은 사회적 문제해결을 위해 사회혁신기업가를 발굴하고 지원하는 글로벌 비영리 조직이다. 이러한 네트워크와 역량을
      바탕으로, 로봇 내 적용될 콘텐츠에 사회적 가치를 더하기 위한 작업을 진행할 것이다. 특히, 아쇼카가 보유한 인사이트 자원을 제공
      및 설계하여 소셜임팩트를 창출할 것으로 예상된다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 해당 AI 반려로봇 사업이 구체화 되면, 내년 상반기 중 B2B, B2C 대상 판매까지 이어질 수 있을 전망이다. 1가정 1반려로봇의
      시대가 열리는 셈이다. 공동개발하는 반려로봇은 팔관절 움직임과 머리 끄덕임, AI기반 인터랙티브 콘텐츠가 특징이다.
      구체적으로는, 해당 특장점을 키즈와 시니어의 니즈에 맞게 커스텀 하여 현재 시장에서 존재하지 않는 새로운 유저 경험(UX)을
      제공하는 것이 핵심이 될 것이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 키즈의 경우 일반적인 학습콘텐츠나 놀이콘텐츠 탑재된 디바이스와 달리, 역동적인 로봇의 움직임을 활용하는 키즈홈트레이닝
      콘텐츠로 비대면시대 새로운 아동체육을 제시하거나, 4차산업혁명시대에 맞춘 로봇코딩 콘텐츠로 아동이 직접 코딩하고 디바이스로
      실증하는 형태의 생활밀착형 교육을 제공할 예정이다. 또한, 심리케어 콘텐츠, 성교육 콘텐츠 등 복지사각지대를 해소, 보완할 수
      있는 콘텐츠들을 풍부하게 접목시키고자 하는 비전을 가지고 있다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 시니어의 경우 케어가 필요한 어르신들을 위한 치매케어나 말벗, 복약지도 콘텐츠 뿐만 아니라 액티브 시니어를 위한 다양한
      콘텐츠들도 맞춤 탑재할 예정이다. 디지털이 어려웠던 어르신들도 해당 반려로봇을 통해 손쉽게 온라인에서 장을 보고, 자신에게
      맞는 복지정책이나 집근처 생활SOC 에서 이루어지는 프로그램들을 구독할 수 있도록 커스텀 하는 등 다양한 시도가 이루어질 것이다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 이를 통해 해당 디바이스는 불쾌한 골짜기를 극복하고, 교감하고 소통하는 정서적 지지자 역할 뿐 아니라 우리집 헬스케어
      도우미, 디지털 가이드 역할이 가능할 것으로 기대해볼 수 있다.
      <br />
    </p>
    <br />
    <p>
      ㅣ KT AI/BigData사업본부장 김채희 상무는 “KT는 고령화 및 언택트 시대의 한계를 극복하기 위해 맞춤형 서비스를 제공하고
      국민기업으로의 책임을 다하겠다”며, “앞으로도 KT는 따뜻한 AI 기술을 통해 노인 및 어린이 등 사회적 손길이 필요한 분들에게
      보탬이 될 수 있도록 하겠다”고 말했다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 아쇼카 한국 이혜영 대표는 아쇼카가 지난 40년 동안 전 세계를 무대로 검증하고 축적해온 사회문제 해결의 다양한 솔루션이
      비즈니스 섹터와 만나 새로운 가치를 만드는 의미있는 실험이 될 것이다. 사회혁신가들의 상상력과 기술의 힘이 만나, 어린이부터
      시니어까지 포스트코로나 시대에도 주체적이고 존엄한 삶을 살아갈 수 있는 더 많은 솔루션이 나오길 기대한다.라고 말했다.
      <br />
    </p>
    <br />
    <p>
      ㅣ 스테이지파이브 이제욱 공동대표는 “본 업무협약은4개사가 각 전문분야를 맡아 사업을 구체화 시키는 의미 있는 첫걸음”이라
      평가하였다. 또한, 서상원 공동대표는 “스테이지 파이브로서는 음성 기반 영역 뿐 아니라 사물인터넷 영역에서도 사업적 확장을
      도모하는 차원이다. 해당 반려로봇을 통해 창출할 통신 산업 소셜임팩트에 주목해달라”고 말했다.
      <br />
    </p>
    <br />
    <div className="descImage">
      <div>
        <img src="/images/press/4/NgZKfsiW.jpg" alt="photo1" />
      </div>
    </div>
    <div className="descImage">
      <div>
        <img src="/images/press/4/mbuaRehy.jpg" alt="photo2" />
      </div>
    </div>
    <div className="descImage">
      <div>
        <img src="/images/press/4/eRUcPTBH.jpg" alt="photo3" />
      </div>
    </div>
  </div>
)

const press4 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com"
      date="2020년 9월 17일 즉시 배포 가능."
      pageInfo="(사진자료 메일 첨부함)"
      title="| 스테이지파이브, KT, NUWA ROBOTICS, 아쇼카 한국과 AI 반려로봇 공동 개발을 위한 업무협약 체결"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press4
